.svgWrapper {
  svg {
    width: 100%;
    height: 100%;
  }
}

.ImageContainer {
  z-index: -1;
}

.ImageBackground {
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 0% 20%;
  // height: 500px;
}

.ImageParallax {
  width: 100%;

  & > * {
    width: 100%;
  }
}
