.Icon {
  fill: currentColor;
}

.alignText {
  padding-bottom: 0.2em;
}

.menu {
  padding-bottom: 0.2em;
  padding-right: 0.2em;
  margin-left: -0.4em;
}
